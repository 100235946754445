@tailwind base;
@tailwind components;
@tailwind utilities;

/* font faces */

@font-face {
	font-family: 'satoshi';
	src: url('./assets/fonts/satoshi.ttf') format('truetype');
}

/* defaults */

body {
	@apply font-satoshi text-txt-regular text-grey-black font-medium bg-sand-light;
}

/* font */

h1 {
	@apply text-hl-large font-bold;
	line-height: 30px;
}

h2 {
	@apply text-hl-regular font-bold;
}

h3 {
	@apply text-hl-small font-bold;
}

p {
	@apply: text-txt-regular;
	line-height: 20px;
}

/* buttons */

button {
	@apply inline-flex items-center px-4 py-3 gap-1 rounded text-txt-regular disabled:opacity-50;
}

button.primary {
	@apply bg-primary;
}

button.secondary {
	@apply bg-sand;
}

button.tertiary {
	@apply bg-white text-grey border border-grey;
}

button.simple {
	@apply text-sand text-txt-small;
}

button.warning {
	@apply bg-signal-red text-white;
}

/* links */

a.text-link {
	@apply underline;
}

/* forms */

input[type="text"],
input[type="file"],
input[type="password"],
input[type="number"],
textarea,
select {
	@apply rounded border border-grey-light px-4 py-3 text-txt-large basis-1 grow w-full focus:ring-0 focus:border-grey;
}

select {
	@apply pr-10;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%23505050' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
}

input[type="file"] {
	@apply block bg-white cursor-pointer p-6;
}

input[type="checkbox"] {
	@apply cursor-pointer text-grey border-grey rounded focus:ring-0 focus:ring-offset-0 focus:bg-transparent;
}

input[type="checkbox"]:checked {
	@apply bg-primary border-grey hover:bg-primary hover:border-grey focus:ring-0 focus:bg-primary focus:border-grey;
	background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

input[type="number"]:read-only {
	@apply bg-sand-light text-sand focus:border-grey-light;
}

/* menu_sidebar */

.menu_sidebar a {
	@apply text-grey hover:bg-grey-light flex items-center gap-x-3 rounded-md p-2 text-txt-large leading-6 font-medium;
}

.menu_sidebar a.active {
	@apply bg-primary;
}

/* tab */

.tab {
	@apply text-sand text-txt-large block mb-4 sm:mb-0 cursor-pointer border-transparent hover:border-sand hover:text-grey-black whitespace-nowrap border-b-2 pb-2;
}

.tab.active {
	@apply text-grey-black cursor-pointer border-primary border-b-2 whitespace-nowrap border-b-4;
}

/* document status */

.document_status {
	@apply text-signal-orange;
}

.document_status[data-value="2"] {
	@apply text-signal-green;
}

.document_status[data-value="3"] {
	@apply text-signal-red;
}